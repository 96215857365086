import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueGtm from 'vue-gtm';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import '../src/assets/css/fontawesome-all.min.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'swiper/css';
import 'swiper/css/effect-fade';
import './assets/css/custom.css'
import './assets/css/custom.scss'
import 'bootstrap'


import { library } from '@fortawesome/fontawesome-svg-core'
import {fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas,  fab)

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .use(i18n)
    .use(VueGtm, {
        id: 'GTM-KCJTHB6G', // Your GTM container ID
        defer: false, // defaults to false. Use defer or async
        enabled: true, // defaults to true. Use to disable or enable GTM
        debug: false, // defaults to false. Use to enable GTM debug mode
        loadScript: true, // defaults to true. If false, the GTM script is not loaded
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      })
      .mount("#app");