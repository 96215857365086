import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
    },
    {
        path: '/',
        name: 'HomeBranding',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../views/HomeBranding.vue'),
    },
    {
        path: '/blogs/:id',
        name: 'viewBlog',
        component: () => import(/* webpackChunkName: "HomeBranding" */ '../components/BlogDisplay.vue'),
    },
    
    {
        path: '/blog-details',
        name: 'BlogDetails',
        component: () => import(/* webpackChunkName: "BlogDetails" */ '../views/blog/BlogDetails.vue'),
    },
    {
        path: '/blogs',
        name: 'ListBlogs',
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/ListBlogsView.vue'),
    },
    {
        path: '/portfilio',
        name: 'gallary',
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/portfolio/ProtfolioView.vue'),
    },
];
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;
